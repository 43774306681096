<template>
  <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" width="900px" @close="close">
    <grid-table ref="gridTable" :tableConfig="tableConfig" :showCheckboxColumn="false" :showPagination="false"
      @rowOperation="handleRowOperation">
    </grid-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "InstallPaymentType",
  data() {
    return {
      visible: false,
      title: "",
      tableConfig: {
        url: "/api/base/payType/GetCommonPayTypeList",
        rowKey: "PaymentID",
        columns: [
          {
            type: "text",
            label: "支付方式ID",
            prop: "PaymentID",
            width: "10px",
            isHidden: true,
          },
          {
            type: "text",
            label: "支付方式",
            prop: "PaymentName",
            width: "150px",
          },
          {
            type: "img",
            label: "支付图片",
            prop: "PaymentImg",
            width: "250px",
          },
          {
            type: "operation",
            label: "操作",
            prop: "InstallStatus",
            width: "150px",
            buttons: [
              { type: "primary", label: "安装", method: "handleInstall" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    init(title) {
      this.title = title;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.gridTable.fetchData();
      });
    },
    handleRowOperation(row, index, method) {
      let methods = this.$options.methods;
      const _this = this;
      methods[method](_this, row);
    },
    async handleInstall(_this, row) {
      await submit("/api/base/payType/Install", {
        paymentID: row.PaymentID,
      });
      _this.$baseMessage("安装成功", "success");
      _this.$refs.gridTable.fetchData();
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
